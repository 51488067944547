<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>逾期订单列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-button type="primary" @click="SelectMerchant" style="margin-right:10px;" v-if="ismerchant != 1" size="small">选择商户</el-button>
                    <el-select v-model="type" style="width:80px;" class="blockitem" size="small">
                        <el-option label="租赁" value="1"></el-option>
                        <el-option label="售卖" value="2"></el-option>
                    </el-select>
                    
                    <el-select v-model="ordertype" style="width:140px;" class="blockitem" size="small">
                        <el-option label="支付宝小程序" value="1"></el-option>
                    </el-select>
                    
                    <el-date-picker v-model="range" 
                                    type="daterange" 
                                    unlink-panels 
                                    range-separator="至" 
                                    start-placeholder="开始时间" 
                                    end-placeholder="结束时间" 
                                    :shortcuts="shortcuts"
                                    size="small"
                                    style="margin-right:10px;"
                                    @selection-change="CheckSelection">
                                    </el-date-picker>
                    <el-input v-model="keyword" placeholder="客户姓名/联系方式/订单编号/物流单号/套餐标题" prefix-icon="el-icon-search" style="width:400px" class="blockitem" size="small"></el-input>
                    <el-button @click="init" type="primary" size="small">查询</el-button>
                    <el-button :disabled="true" size="small">导出</el-button>
                </div>
            </div>
            <div class="content-main">
                <el-divider></el-divider>
                <table style="width: 100%; margin: 1em 0;font-size:14px;">
                    <tr>
                        <th style="width:300px;text-align:left;padding-right:10px;">套餐标题</th>
                        <th style="width:15%;text-align:left;padding-right:10px;">租赁信息</th>
                        <th style="width:10%;text-align:left;padding-right:10px;">押金信息</th>
                        <th style="width:15%;text-align:left;padding-right:10px;">用户信息</th>
                        <th style="width:10%;text-align:left;padding-right:10px;">订单来源</th>
                        <th style="width:10%;text-align:left;padding-right:10px;">订单状态</th>
                        <th style="width:15%;text-align:left">操作</th>
                    </tr>
                </table>
                <div class="order-item" v-for="item in orderdata" :key="item">
                    <div class="orderheader">
                        <span style="margin-right:20px;">
                            <el-checkbox></el-checkbox>
                        </span>
                        <span class="headerspan" >订单编号：{{item.OrderUID}}</span>
                        <span class="headerspan" >下单时间：{{item.AddDTime}}</span>
                        <div v-if="item.Logistic">
                        <span class="headerspan"  v-if="item.Logistic.UID">IMEI/SN：{{item.Logistic.UID}}</span>
                        <span class="headerspan"  v-if="item.Logistic.Name">物流公司: {{item.Logistic.Name}}</span>
                        <span style="font-size:14px;font-weight:bold;color:#757575;" >快递单号: </span>
                        <span v-if="item.Logistic.LogisticNo"><el-button type="text" size="medium" @click="GetLogisticDetail(item.Logistic.LogisticNo)">{{item.Logistic.LogisticNo}}</el-button></span>
                        </div>
                    </div>
                    <table style="width: 100%; margin: 1em 0;font-size:14px;">
                        <tr>
                        <td style="width:300px;text-align:left;padding-right:10px;">
                                <div class="pdt-block">
                                    <el-image :src="item.PdtThumb" style="width:100px;height:auto"></el-image>
                                    <div>{{item.PlanTitle}}</div>
                                </div>
                                
                                
                            </td>
                            <td style="width:15%;text-align:left;padding-right:10px;">
                                <div>用户租期：{{item.RentDay}}天</div>
                                <div>期数：{{item.RentCount}}</div>
                                <div>总租金：{{item.TotalOrderMoney}}</div>
                                <div>到期买断价：{{(item.BuyoutPrice*1 - item.TotalOrderMoney*1)> 0?(item.BuyoutPrice*1 - item.TotalOrderMoney*1).toFixed(2):0.00}}</div>
                                <div>数量：{{item.OrderCount}}</div>
                            </td>
                            <td style="width:10%;text-align:left;padding-right:10px;">
                                <el-button type="text" @click="ShowDeposit(item.ID)">押金信息</el-button>
                                <br>
                                <el-tag size="small" type="success">已冻结押金：{{item.DepositPaid}}</el-tag>
                            </td>
                            <td style="width:15%;text-align:left;padding-right:10px;">
                                <div>联系人：{{item.Receiver}}</div>
                                <div>联系方式：{{item.Mobile}}</div>
                                <div>地址：{{item.province+item.city+item.area+item.Address}}</div>
                                <div>买家备注：{{item.BuyerRemark}}</div>
                                <div>卖家备注：{{item.SellerRemark}}</div>
                            </td>
                            <td style="width:10%;text-align:left;padding-right:10px;">支付宝小程序</td>
                            <td style="width:10%;text-align:left;padding-right:10px;">
                                <div style="display:flex;flex-direction:column;justify-content:center;align-items:flex-start" v-if="item.Status == 6 || item.Status == 12 || item.Status == 24 ">
                                    <span>待归还</span>
                                    <el-tag type="danger" size="mini" v-if="item.Status == 12" style="margin-top:5px;">还机逾期</el-tag>
                                    <el-tag type="danger" size="mini" v-if="item.Status == 24" style="margin-top:5px;">还款逾期</el-tag>
                                </div>
                                <div style="display:flex;flex-direction:column;justify-content:center;align-items:flex-start" v-if="item.Status == 11">
                                    <span>强制买断中</span>
                                    
                                </div>
                            </td>
                            <td style="width:15%;text-align:left">
                                <el-button type="text" @click="OrderDetail(item.ID)">详细信息</el-button>
                                <el-button type="text" @click="AddRemark(item.ID)">备注</el-button>
                                <el-button type="text" @click="Oplog(item.ID)" disabled>操作日志</el-button>
                            </td>
                        </tr>
                    </table>
                    <div class="orderbottom" style="text-align:right">
                        <el-button size="small" @click="Mitigate(item.ID)" v-if="(item.Overdue*1 - item.Mitigate*1) > 0">减免滞纳金</el-button>
                        <el-button size="small" @click="Restore(item.ID)">恢复订单</el-button>
                        <!--<el-button size="small" @click="CheckContract(item.ID)">合同</el-button>-->
                    </div>
                </div>
                
            </div>
            <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
        </el-card>
    </div>
    
    
    <el-dialog v-model="logisticdetail" title="物流详情" center>
        <div>物流公司：{{expName}}</div>
        <div>物流单号：{{number}}</div>
        <el-table :data="logdetail" style="width: 100%; margin: 1em 0" border>
            <el-table-column label="时间" prop="time" width="200px"></el-table-column>
            <el-table-column label="信息" prop="status"></el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog v-model="mitigate" title="减免滞纳金" center width="25%">
        <el-form :model="mitigateinfo" label-width="120">
            <el-form-item label="当前滞纳金">
                {{cur_overdue}}元
            </el-form-item>
            <el-form-item label="输入减免金额" required>
                <el-input-number :controls="false" v-model="mitigateinfo.dmoney" :max="cur_overdue"></el-input-number><br>
                <el-tag type="danger">注意，输入金额不得超过当前滞纳金总额</el-tag>
            </el-form-item>
        </el-form>
        
        <template #footer>
            <el-button size="small" type="primary" @click="SaveMitigate">确定</el-button>
            <el-button size="small" @click="CloseMitigate">取消</el-button>
        </template>
    </el-dialog>

    <el-dialog v-model="depositinfo" title="押金信息" width="20%">
        <div style="padding:10px;">套餐押金：{{plan_deposit}}</div>
        <div style="padding:10px;">免押额度：{{plan_sdeposit + plan_rdeposit}}</div>
        <div style="padding:10px;">补押金：{{plan_replenish}}</div>
        <div style="padding:10px;">已冻结押金：{{plan_depositpaid}}</div>
        <!--<div style="padding:10px;">已解冻押金：{{plan_plan_depositpaid}}</div>-->
    </el-dialog>

    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant"
                filterable
                filter-placeholder="请输入商户名称"
                :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
export default {
    data(){
        return {
            selmerchant:[],
            transfertitle:['商户列表','已选商户'],
            merchantlist:[],
            merchant:false,
            curpage:1,
            pagesize:10,
            totalcount:'',
            plan_deposit:0.00,
            plan_sdeposit:0.00,
            plan_rdeposit:0.00,
            plan_replenish:0.00,
            deposit:0.00,
            depositinfo:false,
            mitigate:false,
            mitigateinfo:{
                orderid:'',
                dmoney:'',
            },
            cur_overdue:'',
            keyword:'',
            range:[],
            
            expName:'',
            number:'',
            
            logistic:false,
            
            shortcuts: [
                {
                    text: '最近一周',
                    value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    return [start, end]
                    },
                },
                {
                    text: '最近一个月',
                    value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    return [start, end]
                    },
                },
                {
                    text: '最近三个月',
                    value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    return [start, end]
                    },
                },
            ],
            ordertype:'1',
            status:'0',
            type:'1',
            
            logisticinfo:{
                orderid:'',
                logisticdata:[],
                returnid:'1'
            },
            
            
            orderdata:[],
            
            logisticdetail:false,
        }
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_order_list,{
            headers: {
                "Content-Type": "application/json",
            },
            params:{
                status: 12,
                curpage:this.curpage,
                pagesize:this.pagesize,
                ismerchant:this.ismerchant,
                mid:this.mid,
            }
        }).then((response)=>{
            console.log(response.data);
            this.orderdata = response.data.list;
            this.curpage = response.data.curpage*1;
            this.totalcount = response.data.totalcount*1;
        })

        this.axios.get(constant.allmerchant,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for(let i = 0;i<list.length;i++){
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    },
    methods:{
        Oplog(id){
            this.axios.get(constant.getoplog,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    orderid: id,
                }
            }).then((response)=>{
                console.log(response.data);
                this.oplogdialog = true;
                this.oplogdata = response.data;
            })
        },
        ConfirmMerchant(){
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back(){
            this.merchant = false;
        },
        SelectMerchant(){
            this.merchant = true;
        },
        Restore(id){
            this.axios.get(constant.restoreorder,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    orderid:id
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }else if(response.data == "OVERDUE"){
                    this.$message.error("还有滞纳金未缴纳");
                    return false;
                }
            })
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            this.pagesize = val;
            this.init();
        },
        init(){
            this.axios.get(constant.get_order_list,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    status: 12,
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    
                    range:this.range,
                    keyword:this.keyword,
                    type:this.type,
                    
                }
            }).then((response)=>{
                console.log(response.data);
                this.orderdata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        ShowDeposit(orderid){
            this.depositinfo = true;
            this.axios.get(constant.get_deposit,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    orderid: orderid
                }
            }).then((response)=>{
                console.log(response.data);
                this.plan_deposit = response.data.PlanDeposit*1;
                this.plan_sdeposit = response.data.SDecrease*1;
                this.plan_rdeposit = response.data.RDecrease*1;
                this.plan_replenish = response.data.Replenish*1;
                this.plan_depositpaid = response.data.DepositPaid*1;
                this.deposit = response.data.Deposit*1;
            });
        },
        SaveMitigate(){
            if(this.mitigateinfo.dmoney > this.cur_overdue){
                this.$message.error("金额不能超过当前滞纳金金额");
                return false;
            }
            if(this.mitigateinfo.dmoney == this.cur_overdue){
                this.$confirm("确定要减免全部滞纳金么？","提示",{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(()=>{
                    this.axios.post(constant.save_mitigate,this.mitigateinfo,{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == "OK"){
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        }
                    });
                }).catch(()=>{
                    return false;
                })
            }else{
                this.axios.post(constant.save_mitigate,this.mitigateinfo,{
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }
                });
            }
            
        },
        CloseMitigate(){
            this.mitigateinfo.orderid = "";
            this.mitigateinfo.dmoney = "";
            this.mitigate = false;
        },
        CloseLogistic(){
            this.logistic = false;
            this.logisticinfo.logisticdata = [];
            this.logisticinfo.orderid='';
        },
        
        
        OrderDetail(id){
            this.$router.push({path:'/orderdetail',query:{id:id}})
        },
        GetLogisticDetail(logno){
            this.axios.get(constant.get_logistic_detail,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    logno: logno
                }
            }).then((response)=>{
                console.log(response.data);
                this.expName = response.data.result.expName;
                this.number = response.data.result.number;
                this.logdetail = response.data.result.list;
                this.logisticdetail = true;
            });
            return false;
        },
        
        Mitigate(orderid){
            console.log(orderid);
            this.mitigateinfo.orderid = orderid;
            this.axios.get(constant.get_decrease,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    orderid: orderid
                }
            }).then((response)=>{
                console.log(response.data);
                this.cur_overdue = ((response.data.Overdue*1 - response.data.Mitigate*1).toFixed(2))*1;
                this.decrease = true;
                this.mitigate = true;
            })
            
        }
    }
}
</script>
<style scoped>
.blockitem {
    margin-right: 10px;
}
.orderheader, .orderbottom{
    border-top: 1px solid #eeeded;
    border-bottom: 1px solid #eeeded;
    padding: 5px;
    background-color: #f6fbff;
    display: flex;
    align-items: center;
    height: 32px;
}
.headerspan {
    margin-right:20px;
    font-size:14px;
    font-weight:bold;
    color:#757575;
}
.bottom{
    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pdt-block {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>